import React from 'react';
import { useSpring, animated } from 'react-spring';
import { FaTimes } from "react-icons/fa";
import failedpng from '../assets/round-error-icon-16-removebg-preview.png'

const FailedPage = () => {
  // Define animations using react-spring
  const fadeProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const shakeProps = useSpring({
    from: { transform: 'translateX(0px)' },
    to: [
      { transform: 'translateX(-10px)' },
      { transform: 'translateX(10px)' },
      { transform: 'translateX(-5px)' },
      { transform: 'translateX(5px)' },
      { transform: 'translateX(0px)' },
    ],
    config: { duration: 400 },
  });

  return (
    <div className="page-wrapper">
    <div className="custom-modal">
        <div className="danger danger-animation icon-top"><i className="fa fa-times"><FaTimes /></i></div>
        <div className="danger border-bottom"></div>
        <div className="content">
            <p className="type">Payment not received.</p>
            <p className="message-type">Your transaction was failed.!</p>
        </div>
    </div>

    {/* <div className="custom-modal">
        <div className="danger danger-animation icon-top"><i className="fa fa-times"></i></div>
        <div className="danger border-bottom"></div>
        <div className="content">
            <p className="type">Appointment</p>
            <p className="message-type">A Problem Occurred </p>
        </div>
    </div> */}
</div>
  );
};

export default FailedPage;
