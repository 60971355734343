import { Link, Route, Switch } from "react-router-dom";
import Home from "./App";
import Result from "./result";
import TxnEnquiry from "./components/TxnEnquiry";
import Refund from "./components/Refund";
import Faildepage from "./components/Faildepage";
import Succespage from "./components/Succespage";
import Payment from "./components/payment";



const RoutePage = () => {
  return (
    <>
      <Switch>

        <Route exact path="/">
        <Home/>
        </Route>
        <Route path="/result">
          <Result />
        </Route>
        <Route path="/txnenquiry">
          <TxnEnquiry />
        </Route>
        <Route path="/refund">
          <Refund />
        </Route>
        
        <Route path="/failed">
          <Faildepage />
        </Route>
        <Route path="/success">
          <Succespage />
        </Route>
        
      </Switch>
    </>
  );
};
export default RoutePage;
