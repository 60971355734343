// import React, { useState, useEffect } from 'react'
// import { PaymentInitModal } from "pg-test-project";
// import { v4 as uuidv4 } from 'uuid';
// import uniqid from 'uniqid';
// import { useHistory } from 'react-router-dom'
// import { NavLink } from 'react-router-dom';


// function SabpaisaPaymentGateway(props) {


//   const [isOpen, setIsOpen] = useState(false);
//   const [clientCode, setClientCode] = useState(props.clientCode);
//   const [transUserName, setTransUserName] = useState(props.transUserName);
//   const [transUserPassword, setTransUserPassword] = useState(props.transUserPassword);
//   const [authkey, setAuthkey] = useState(props.authkey);
//   const [authiv, setAuthiv] = useState(props.authiv);
//   const [payerName, setpayerName] = useState(props.payerName);
//   const [payerEmail, setpayerEmail] = useState(props.payerEmail);
//   const [payerMobile, setpayerMobile] = useState(props.payerMobile);
//   const [clientTxnId, setclientTxnId] = useState(props.clientTxnId);
//   const [amount, setamount] = useState(props.amount);
//   const [payerAddress, setpayerAddress] = useState(props.payerAddress);
//   const [callbackUrl, setCallbackUrl] = useState(props.callbackUrl);
//   const [amountType, setamountType] = useState("");
//   const [udf1, setudf1] = useState("");
//   const [udf2, setudf2] = useState("");
//   const [udf3, setudf3] = useState("");
//   const [udf4, setudf4] = useState("");
//   const [udf5, setudf5] = useState("");
//   const [udf6, setudf6] = useState("");
//   const [udf7, setudf7] = useState("");
//   const [udf8, setudf8] = useState("");
//   const [udf9, setudf9] = useState("");
//   const [udf10, setudf10] = useState("");
//   const [udf11, setudf11] = useState("");
//   const [udf12, setudf12] = useState(""); // client id
//   const [udf13, setudf13] = useState(""); // plan id
//   const [udf14, setudf14] = useState(""); // plan name
//   const [udf15, setudf15] = useState(""); // application id (product id)
//   const [udf16, setudf16] = useState(""); // // client subscribe plan detail id(refer to DB)
//   const [udf17, setudf17] = useState(""); // payment from the COB portal
//   const [udf18, setudf18] = useState("");
//   const [udf19, setudf19] = useState("");
//   const [udf20, setudf20] = useState("");
//   const [channelId, setchannelId] = useState("");
//   const [programId, setprogramId] = useState("");
//   const [mcc, setmcc] = useState("");



//   // const params = new URLSearchParams(window.location.search);

//   // // Extracting required parameters
//   // const name = params.get('payerName');
//   // const email = params.get('payerEmail');
//   // const status = params.get('status');
//   // const price = params.get('amount');

//   // // Constructing the new URL
//   // const newURL = `myapp://example.com?amount=${price}&payerName=${name}&payerEmail=${email}&status=${status}`;

//   const history = useHistory();
//   useEffect(() => {
//     setIsOpen(true);

//     // Check if payment response exists
//     const responseValues = history?.location?.search;
//     const status = new URLSearchParams(responseValues).get('status');
//     console.log('status =>', status);
//     console.log('responseValues =>', responseValues);


//     // Redirect logic upon successful payment
//     if (status=='FAILED') {
//       console.log('isOpn =>',isOpen);

//       setIsOpen(false);
//       console.log("Redirecting...");
//       const name = new URLSearchParams(responseValues).get('payerName');
//       const email = new URLSearchParams(responseValues).get('payerEmail');
//       const price = new URLSearchParams(responseValues).get('amount');
//       const failedURL = `myapp://example.com?amount=${price}&payerName=${name}&payerEmail=${email}&status=${status}`;
//       window.location.href = failedURL;

//     }
//   }, [props.isOpen, history]);

//   // useEffect(() => {
//   //   setIsOpen(true);

//   //   // Simulate successful payment after 2 seconds
//   //   const timer = setTimeout(() => {
//   //     setIsOpen(false);
//   //     const status = 'FAILED'; // Simulate status
//   //     if (status === 'FAILED') {
//   //       history.push('https://www.merriam-webster.com/dictionary/demo');
//   //     }
//   //   }, 2000);

//   //   return () => clearTimeout(timer);
//   // }, [history]);



//   return (
//     <div>

//       {isOpen && (
//         <PaymentInitModal
//           {...props}
//           clientCode={clientCode}
//           transUserPassword={transUserPassword}
//           transUserName={transUserName}
//           isOpen={isOpen}
//           clientTxnId={uniqid()}
//           authkey={authkey}
//           authiv={authiv}
//           payerName={payerName}
//           payerEmail={payerEmail}
//           payerMobile={payerMobile}
//           payerAddress={payerAddress}
//           amount={amount}
//           amountType={amountType}
//           udf12={udf12}
//           udf13={udf13}
//           udf14={udf14}
//           udf15={udf15}
//           udf16={udf16}
//           udf17={udf17}
//           onToggle={() => setIsOpen(!isOpen)}
//           channelId={channelId}
//           programId={programId}
//           mcc={mcc}
//           // label={"Production"}
//           env={'prod'}
//         />
//       )}
//     </div>




//   )
// }

// export default SabpaisaPaymentGateway


// old code

import React, { useState, useEffect } from 'react'
import { PaymentInitModal } from "pg-test-project";
import { v4 as uuidv4 } from 'uuid';
import uniqid from 'uniqid';
import { useHistory } from 'react-router-dom'

import { useLocation } from "react-router-dom";


function SabpaisaPaymentGateway(props) {
  console.log('props =>', props);


  const [isOpen, setIsOpen] = useState(false);
  const [clientCode, setClientCode] = useState(props.clientCode);
  const [transUserName, setTransUserName] = useState(props.transUserName);
  const [transUserPassword, setTransUserPassword] = useState(props.transUserPassword);
  const [authkey, setAuthkey] = useState(props.authkey);
  const [authiv, setAuthiv] = useState(props.authiv);
  const [payerName, setpayerName] = useState(props.payerName);
  const [payerEmail, setpayerEmail] = useState(props.payerEmail);
  const [payerMobile, setpayerMobile] = useState(props.payerMobile);
  const [clientTxnId, setclientTxnId] = useState(props.clientTxnId);
  const [amount, setamount] = useState(props.amount);
  const [payerAddress, setpayerAddress] = useState(props.payerAddress);
  const [callbackUrl, setCallbackUrl] = useState(props.callbackUrl);
  const [amountType, setamountType] = useState("");
  const [udf1, setudf1] = useState("");
  const [udf2, setudf2] = useState("");
  const [udf3, setudf3] = useState("");
  const [udf4, setudf4] = useState("");
  const [udf5, setudf5] = useState("");
  const [udf6, setudf6] = useState("");
  const [udf7, setudf7] = useState("");
  const [udf8, setudf8] = useState("");
  const [udf9, setudf9] = useState("");
  const [udf10, setudf10] = useState("");
  const [udf11, setudf11] = useState("");
  const [udf12, setudf12] = useState(""); // client id
  const [udf13, setudf13] = useState(""); // plan id
  const [udf14, setudf14] = useState(""); // plan name
  const [udf15, setudf15] = useState(""); // application id (product id)
  const [udf16, setudf16] = useState(""); // // client subscribe plan detail id(refer to DB)
  const [udf17, setudf17] = useState(""); // payment from the COB portal
  const [udf18, setudf18] = useState("");
  const [udf19, setudf19] = useState("");
  const [udf20, setudf20] = useState("");
  const [channelId, setchannelId] = useState("");
  const [programId, setprogramId] = useState("");
  const [mcc, setmcc] = useState("");


  const history = useHistory();
  console.log("32")

  useEffect(() => {
    setIsOpen(props.isOpen);
  
    // Check if payment response exists
    const responseValues = history?.location?.search;
    console.log(responseValues)
    const status = new URLSearchParams(responseValues).get('status');
  
    // Redirect logic upon failed payment

    // success
    if (status === 'SUCCESS') {
      setIsOpen(false);
      console.log("Redirecting...");

      // Navigate to /failed path
      history.push('/success');

      setTimeout(() => {
        console.log("Redirecting to another page...");
        const name = new URLSearchParams(responseValues).get('payerName');
        const email = new URLSearchParams(responseValues).get('payerEmail');
        const price = new URLSearchParams(responseValues).get('amount');
        const status = 'SUCCESS';
        const nextURL = `payment://viratrunner.com?amount=${price}&payerName=${name}&payerEmail=${email}&status=${status}`;
        window.location.href = nextURL;
      }, 2000); 
    }
    else if(status === 'FAILED'){
      setIsOpen(false);
      console.log("Redirecting...");

      // Navigate to /failed path
      history.push('/failed');

      setTimeout(() => {
        console.log("Redirecting to another page...");
        const name = new URLSearchParams(responseValues).get('payerName');
        const email = new URLSearchParams(responseValues).get('payerEmail');
        const price = new URLSearchParams(responseValues).get('amount');
        const status = 'FAILED';
        const nextURL = `payment://viratrunner.com?amount=${price}&payerName=${name}&payerEmail=${email}&status=${status}`;
        window.location.href = nextURL;
      }, 2000); 
    }
  }, [props.isOpen, history]);




  return (
    <div>
      {/* {isOpen && ( */}
        <PaymentInitModal
          clientCode={clientCode}
          transUserPassword={transUserPassword}
          transUserName={transUserName}
          isOpen={isOpen}
          clientTxnId={uniqid()}
          authkey={authkey}
          authiv={authiv}
          payerName={payerName}
          payerEmail={payerEmail}
          payerMobile={payerMobile}
          payerAddress={payerAddress}
          amount={amount}
          amountType={amountType}
          udf12={udf12}
          udf13={udf13}
          udf14={udf14}
          udf15={udf15}
          udf16={udf16}
          udf17={udf17}
          onToggle={() => setIsOpen(!isOpen)}
          channelId={channelId}
          programId={programId}
          mcc={mcc}
          // label={"Production"}
          env={'prod'}
        />
      {/* )} */}
    </div>




  )
}

export default SabpaisaPaymentGateway