import React from 'react'
import { FaCheck } from 'react-icons/fa'

const Succespage = () => {
    return (

        <div className="page-wrapper">
            <div className="custom-modal">
                <div className="succes succes-animation icon-top"><i className="fa fa-check"><FaCheck/></i></div>
                <div className="succes border-bottom"></div>
                <div className="content">
                    <p className="type" style={{color:"rgb(14, 119, 14)"}}>Payment received.</p>
                    <p className="message-type">Your transaction was Successfully.!</p>
                </div>
            </div>
        </div>
    )
}

export default Succespage