import React, { useEffect, useState } from 'react';
import paytm from '../assets/Images/sabpaisa.png';
import phonepe from '../assets/Images/phonepe.png';
import { useLocation, useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import SabpaisaPaymentGateway from '../SabpaisaPaymentGateway';
import uniqid from 'uniqid';
const { encrypt, decrypt } = require('n-krypta');


export default function Payment() {
    const [selectedPayment, setSelectedPayment] = useState("SabPaisa");


    // app js code 
    const location = useLocation();
    const history = useHistory()
    console.log("1")
    const searchParams = new URLSearchParams(location.search);
    console.log("2")

    const amountgett = searchParams.get('amount');
    console.log("3")
    const namegett = searchParams.get('payerName');
    console.log("4")
    const emailgett = searchParams.get('payerEmail');
    console.log("5")
    const [isOpen, setIsOpen] = useState(false);

    const [clientCode, setClientCode] = useState("RAVI76");
    const [transUserName, setTransUserName] = useState("ravi.onepluse_15268");
    const [transUserPassword, setTransUserPassword] = useState("RAVI76_SP15268");
    const [authkey, setAuthkey] = useState("b6wcogQ8RX2V3rxR");
    const [authiv, setAuthiv] = useState("rT5lcnOUCMPxAzZG");
    const [payerName, setPayerName] = useState(amountgett);
    const [payerEmail, setPayerEmail] = useState(namegett);
    const [payerMobile, setPayerMobile] = useState("6291312929");
    const [clientTxnId, setclientTxnId] = useState(uniqid());
    const [amount, setAmount] = useState(emailgett);
    const [payerAddress, setPayerAddress] = useState("Kolkata");
    const [callbackUrl, setCallbackUrl] = useState("https://axispay.cloud/response");
    const [data, setData] = useState(null)
    const [udf1, setudf1] = useState(null);
    const [udf2, setudf2] = useState(null);
    const [udf3, setudf3] = useState(null);
    const [udf4, setudf4] = useState(null);
    const [udf5, setudf5] = useState(null);
    const [udf6, setudf6] = useState(null);
    const [udf7, setudf7] = useState(null);
    const [udf8, setudf8] = useState(null);
    const [udf9, setudf9] = useState(null);
    const [udf10, setudf10] = useState(null);
    const [udf11, setudf11] = useState(null);
    const [udf12, setudf12] = useState(null); // client id
    const [udf13, setudf13] = useState(null); // plan id
    const [udf14, setudf14] = useState(null); // plan name
    const [udf15, setudf15] = useState(null); // application id (product id)
    const [udf16, setudf16] = useState(null); // client subscribe plan detail id(refer to DB)
    const [udf17, setudf17] = useState(null); // payment from the COB portal
    const [udf18, setudf18] = useState(null);
    const [udf19, setudf19] = useState(null);
    const [udf20, setudf20] = useState(null);
    const [channelId, setchannelId] = useState(null);
    const [programId, setprogramId] = useState(null);
    const [mcc, setmcc] = useState(null);
    const [amountType, setamountType] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');


    const searchParamss = new URLSearchParams(location.search);
    console.log("6")
    console.log('searchParamss =>', searchParamss);
    console.log("7")
    const qget = searchParamss?.get('q')?.replace(/\s/g, "+");
    console.log("8")
    const keydata = searchParamss?.get('tnxID');
    console.log("9")
    console.log('qget************** =>', qget);
    console.log("10")
    console.log('keydata =>', keydata);
    console.log("11")


    //     const replaceurl = qget.replace(/\s/g, "+");
    //     console.log("12")
    // console.log('replaceurl =>',replaceurl);





    // const my_key = ;
    const key = CryptoJS.enc.Utf8.parse(keydata);
    console.log("13")
    console.log('key =>', key);
    console.log("14")

    // const iv = keydata ? CryptoJS.enc.Utf8.parse(keydata.substring(0, 16)) : null;

    const iv = CryptoJS.enc.Utf8.parse(keydata?.substring(0, 16));
    console.log("18")
    console.log('iv =>', iv);



    const decryptData = (qget, key, iv) => {
        // console.log('storedAmount =>', qget, key, iv);
        console.log("19")
        try {
            // Decrypt the data using CryptoJS
            console.log("20")
            const bytes = CryptoJS.AES.decrypt(qget, key, {

                iv: iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            console.log("21")
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedString;
        } catch (error) {
            console.log("22")
            console.error('Decryption error:', error.message);
            return null;
        }
    };




    function decryptURL(qget ) {
        var decryptedUrl = '';
        for (var i = 0; i < qget?.length; i++) {
            var decryptedChar = String.fromCharCode(qget.charCodeAt(i) - 1); // Reverse the character shifting by 1
            decryptedUrl += decryptedChar;
        }
        return decryptedUrl;
    }

    // Example usage:
// var encryptedUrl = "@bnpvou>348'qbzfsObnf>Cibvujl!I'qbzfsFnbjm>cibvujli:74Ahnbjm/dpn";
// console.log("Encrypted URL: " + encryptedUrl);
var decryptedUrl = decryptURL(qget);
console.log("Decrypted URL: " + decryptedUrl);


    // const decryptedValueparams = decryptData(qget, key, iv);
    // console.log("23")
    // console.log("decryptedValueparams", decryptedValueparams);

    // Parse the decrypted URL string into URLSearchParams object
    const params = new URLSearchParams(decryptedUrl);
    console.log("24")
    // Extract values using get() method
    const amountget = params.get("amount");
    console.log("25")
    const price = amountget * 100;
    console.log("26")
    const nameget = params.get("payerName");
    console.log("27")
    const emailget = params.get("payerEmail");
    console.log("28")




    const handlePaymentSelection = (paymentMethod) => {
        console.log("29")
        setSelectedPayment(paymentMethod);
    };

    const handlePaymentConfirmation = () => {

        console.log("30")
        if (selectedPayment === 'SabPaisa') {
            setIsOpen(true);
            // <SabpaisaPaymentGateway/>
        } else {
            setIsOpen(false);
        }
        // if(selectedPayment === 'PhonePe'){
        //     let paymentLink ='';
        //     paymentLink = `https://upi.hindustanrides.in/index.php?q=${qget}`;
        //     window.location.href = paymentLink;
        // }
    };

    return (
        <>

            <SabpaisaPaymentGateway clientCode={clientCode} transUserName={transUserName} transUserPassword={transUserPassword} authkey={authkey} authiv={authiv} payerName={nameget} payerEmail={emailget} payerMobile={payerMobile} clientTxnId={clientTxnId} amount={amountget} payerAddress={payerAddress} callbackUrl={callbackUrl} isOpen={isOpen} keydata={keydata}
            />

            <div className='h-36 bg-gradient-to-r from-[#4c60f5] to-[#53bbfa]' ></div>
            <div className='flex items-center justify-center mt-[-20px] sm:mt-[-50px] lg:mt-[-50px] xl:mt-[-100px] 2xl:mt-[-100px]' >
                <div className='bg-white w-full sm:w-[90%] xl:w-[90%]  2xl:w-[90%] lg:w-[60%] rounded-lg p-5 overflow-y-auto max-h-[80%] box' style={{ border: "1px solid #3b82f6;" }}>
                    <p className='text-center text-[30px] font-bold'> ₹ {amountget} </p>
                    <p className='text-center text-[14px] mt-[-5px]'>The amount you need to pay</p>
                    <div className="mx-auto mt-10 max-w-full lg:px-4 xmd:px-4 md:px-4 xsm:px-4 sm:px-2 xl:px-0 2xl:px-0">
                        <h2 className="font-medium text-gray-800">Select your payment method</h2>
                        <ul className="mt-6 space-y-3">

                           

                            <li>
                                <label htmlFor='SabPaisa' className='relative block'>
                                    <input
                                        id='SabPaisa'
                                        type='radio'
                                        name='payment'
                                        className='peer sr-only'
                                        onChange={() => handlePaymentSelection('SabPaisa')}
                                        checked={selectedPayment === 'SabPaisa'}  // This line makes the radio button checked by default
                                    />
                                    <div className={`flex w-full cursor-pointer items-start justify-between gap-x-3 rounded-lg border border-gray-300 bg-white p-4 
                shadow-sm duration-200 ${selectedPayment === 'SabPaisa' ? 'ring-2 ring-blue-500' : ''}`}>
                                        <div className='flex-none'>
                                            <img src={paytm} alt='Paytm' className='w-11 h-[34px]  object-contain' />
                                        </div>
                                        <div className='flex-grow'>
                                            <div>
                                                <h3 className='pr-3 font-medium leading-none text-gray-800'>SabPaisa</h3>
                                                <p className='mt-1 text-sm text-gray-600'>Cards, UPI, Netbanking</p>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="w-6 h-6 rounded-full bg-white border border-gray-300 flex items-center justify-center focus:outline-none"
                                            aria-label="Select Payment Method"
                                        >
                                            {selectedPayment === 'SabPaisa' && (
                                                <span className="w-4 h-4 rounded-full bg-blue-500 border border-blue-500"></span>
                                            )}
                                        </button>
                                    </div>
                                </label>
                            </li>


                        </ul>

                        <button
                            type='submit'
                            className='mt-10 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 block px-24 mx-auto rounded w-full'
                            onClick={handlePaymentConfirmation}
                            disabled={!selectedPayment} // Disable button if no payment method is selected
                        >
                            Pay Now ₹ {amountget}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}